import React, {useState} from 'react';
import '../scss/style.scss';
import Input from "../components/Input";
import Divider from "../components/Divider";
import Toast from "../utils/Toast";
import AxiosInstance from "../utils/AxiosInstance";
import Footer from "../components/footer";
import { useHistory } from "react-router"
import {Link} from "react-router-dom";

const IndexPage = (props: {roomID?: string}) : JSX.Element => {
    const [CodeField, setCodeField] = useState<string>(props.roomID);
    const [CodeError, setCodeError] = useState<string>("");

    const [NameField, setNameField] = useState<string>("");
    const [NameError, setNameError] = useState<string>("");

    const history = useHistory();

    const connectToRoom = () => {
        setCodeError("");
        setNameError("");

        //validation
        if(CodeField === "") {setCodeError("Please insert room code!"); return false;}

        if(NameField === "") {setNameError("Please insert your username"); return false;}
        if(NameField.length < 3) {setNameError("Your username must have atleast 3 characters"); return false;}

        AxiosInstance.post("/room/connect",{userName: NameField, roomId: CodeField})
            .then((response) => {
                window.localStorage.setItem("room-"+CodeField, JSON.stringify(response.data));

                history.push("/room/"+CodeField);
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Connection Error, Room do not exist!'
                });
            });
    };


    return (
        <div className="com-welcome">
            <div className="welcome">
                <div className="left">
                    <h1>Team Video Chart</h1>

                    <Input title={"Room code"} placeholder={"Insert room code"} defaultValue={props.roomID} errorMessage={CodeError} onChange={(value) => {setCodeField(value)}}/>

                    <Input title={"Username"} placeholder={"Insert your username"} errorMessage={NameError} onChange={(value) => {setNameField(value)}}/>

                    <div className="btn w-100 green" onClick={connectToRoom}><i className="fas fa-plug"></i> Connect</div>

                    <Divider>OR</Divider>

                    <Link to="/create-room">
                        <div className="btn w-100"><i className="far fa-plus-square"></i> Create new room</div>
                    </Link>
                </div>
                <div className="right" style={{backgroundImage: "url('/img/bg-2.jpg')"}}>

                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default IndexPage;
