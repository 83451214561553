import React, {useEffect, useState} from "react";
import {useHistory} from "react-router"
import Toast from "../utils/Toast";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Player2 from "../components/Player2";
import { Config } from "../config";

type Song = {
    songId: string
    youtubeId: string

    songName: string

    userId: string
    userName: string

    currentScore: number
    ratings: Array<object>

    hasPlayed: boolean
}

const Room = (props: { roomId?: string }) => {
    const bufferTime = 5;

    const history = useHistory();

    //MAIN STATES
    const [roomData, setRoomData] = useState({"userId": "", "userName": "", "isAdmin": false, "canControl": false, "canAdd": true, "roomName": "", "adminName": ""});
    const [chart, setChart] = useState<Array<Song>>([]);

    const [video, setVideo] = useState<any>({song: null, state: "pause", time: 0, since: 0});

    const [online, setOnline] = useState<Array<any>>([]);

    //modals
    const [modalAddSong, setModalAddSong] = useState(false);
    const [modalRate, setModalRate] = useState(false);
    const [shareModal, setShareModal] = useState<boolean>(false);

    //global prop
    const [client, setClient] = useState(null);
    const [error, setError] = useState<string>("");

    //INPUTS
    const [inputAddSong, setInputAddSong] = useState<string>("");
    const [rating, setRating] = useState(null);


    const getMyRating = (song) => {
        if(song?.ratings) return 0

        return song.ratings.filter((rate) => rate.userId === roomData.userId)[0].rating;
    }

    useEffect(() => {
        let errorR = false;

        if (props.roomId === "") {
            Toast.fire({
                icon: 'error',
                title: 'You do not have room id in url (redirectingo to connect page)!'
            });
            history.push("/connect/");
            errorR = true;
        }

        if (window.localStorage.getItem("room-" + props.roomId) == null && !error) {
            Toast.fire({
                icon: 'error',
                title: 'Connection Error, try it again!'
            });

            history.push("/connect/" + props.roomId);
            errorR = true;
        } else {
            setRoomData(JSON.parse(window.localStorage.getItem("room-" + props.roomId)));
        }


        if (!errorR) {
            let nC = new WebSocket(Config.WS_API_URI);

            nC.onopen = () => {
                Toast.fire({
                    icon: 'success',
                    title: 'Connected!'
                });

                nC.send(JSON.stringify({
                    "type": "client-connect",
                    "userId": JSON.parse(window.localStorage.getItem("room-" + props.roomId)).userId
                }));
            };


            nC.onmessage = (evt) => {
                const data = JSON.parse(evt.data);

                console.log(data)

                if(data.type === "user-data") {
                    setOnline(data.activeUsers);
                }

                if(data.type === "song-data") {
                    setChart(data.songs);
                }

                if(data.type === "current-song-data") {
                    if(data && data.currentSong) {
                        setVideo({song: data.currentSong, state: data.state, time: data.time, since: data.since + bufferTime});
                    }
                }

                if(data.type === "error") {
                    setError(data.message);
                    //nC.close();
                }

                if(data.type === "notification") {
                    if(data.message === "NEW_USER_CONNECTED") {
                        Toast.fire({
                            icon: 'info',
                            title: data.data.newUserName+' has just joined. Welcome!'
                        });
                    }
                    if(data.message === "NEW_SONG_ADDED ") {
                        Toast.fire({
                            icon: 'info',
                            title: 'Somebody add video '+data.data.addedSongName
                        });
                    }

                    if(data.message === "USER_DISCONNECTED ") {
                        Toast.fire({
                            icon: 'info',
                            title: data.data.disconnectedUserName+' has disconnected :('
                        });
                    }

                    if(data.message === "CURRENT_SONG_CHANGED") {
                        Toast.fire({
                            icon: 'info',
                            title: 'Somebody started playing '+data.data.newSongName
                        });
                    }

                    if(data.message === "SONG_REMOVED") {
                        Toast.fire({
                            icon: 'info',
                            title: data.data.userName+' removed '+data.data.songName
                        });
                    }
                }
            };

            nC.onclose = () => {
                Toast.fire({
                    icon: 'error',
                    title: 'Disconnected!'
                });

                setError("Disconnected");
            }

            setClient(nC);
        }

    }, []);

    const sendAddModal = () : any => {
        client.send(
            JSON.stringify({
                "type": "song-add",
                "songUrl": inputAddSong
            })
        );

        setModalAddSong(false);
    }

    const openRating = (song: Song, showCounts : boolean = true) : any => {
        setRating({song: song, showConts: showCounts});
        setModalRate(true);
    }

    const sendRating = (num : number) => {
        client.send(JSON.stringify({
            type: "song-rate",
	        songId: rating.song.songId,
            newRating: num
        }));

        setModalRate(false);
    }

    const removeSong = (songId : string) : any => {
        client.send(JSON.stringify({
            type: "song-remove",
            songId: songId
        }));
    }

    const playSong = (songId: string) : any => {
        client.send(JSON.stringify({
            type: "song-change",
	        newSongId: songId,
            state: "playing",
            time: 0
        }));
    }

    const devChart = () => {
        client.send(
            JSON.stringify({
                "type": "song-add",
                "songUrl":  "https://www.youtube.com/watch?v=dL0NFSeR0KA"
            })
        );

        client.send(
            JSON.stringify({
                "type": "song-add",
                "songUrl":  "https://www.youtube.com/watch?v=XEjLoHdbVeE"
            })
        );

        client.send(
            JSON.stringify({
                "type": "song-add",
                "songUrl":  "https://www.youtube.com/watch?v=LPcP3Oc9oiM"
            })
        );
    };

    const copyText = () => {
        let copyText = document.getElementById('copy-text');
        // @ts-ignore
        copyText.select();
        // @ts-ignore
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");

        Toast.fire({
            icon: 'info',
            title: 'link copied to clickboard!'
        });

        return true;
    };

    return (
        <div className="container com-main-room">
            <h1>
                {error && (<span className="badge red click" onClick={() => setError("")}><i className="fas fa-bug"></i> {error}</span>)}
                {roomData.isAdmin && <span className="badge green"><i className="fas fa-crown"></i> Admin</span>}
                {roomData.roomName}
            </h1>
            <div className="room-info">
                <div className="item">
                    <i className="fas fa-user"></i> {roomData.userName}
                </div>
                <div className="item">
                    <i className="fas fa-globe-americas"></i> online: {online.length}
                </div>
                <div className="item">
                    {roomData.canControl ? <i className="fas fa-check"></i> : <i className="fas fa-ban"></i>} Video Control
                </div>
                <div className="item">
                    {roomData.canAdd ? <i className="fas fa-check"></i> : <i className="fas fa-ban"></i>} Add Viddeo
                </div>
                {
                    roomData.isAdmin && <div className="item"><i className="fas fa-user-cog"></i> Admin</div>
                }

            </div>
            <div className="com-room">
                <div className="video-frame">
                    {/*<Player data={video} client={client} roomData={roomData} openRating={openRating} bufferTime={bufferTime}/>*/}
                    <Player2 data={video} client={client} roomData={roomData} openRating={openRating}/>

                    <div className="online">
                        <div className="online-title">Online clients ({online.length})</div>
                        {online.map((person) => <div className={"person"} key={Math.random()}><i className="fas fa-user"></i> {person.name}</div> )}
                    </div>
                </div>
                <div className="sub">
                    <div className="title-flex">
                        <h2>Chart </h2>
                        {roomData.canAdd && <span className="btn" onClick={() => setModalAddSong(true)}>Add Video</span>}
                        {roomData.userName === "dev" && <span className="btn" onClick={() => devChart()}>Add dev songs</span>}
                        <span className="btn" onClick={() => {setShareModal(true)}}><i className="fas fa-share-square"></i> Share room link</span>

                        {/*<div className="btn" onClick={devChart}>Add song (DEV)</div>*/}
                    </div>

                    {
                        chart.sort((a, b) => a.currentScore > b.currentScore ? -1 : 1).map((song, index) =>
                            <div className="song-item">
                                <div className="possition">{index + 1}</div>
                                <h3>{song.songName}</h3>
                                <div>
                                    <div className="score"><i className="fas fa-star"></i> {song.currentScore} points</div>
                                    <div className="score"><i className="fas fa-user"></i> {song.userName}</div>
                                    {
                                        song.hasPlayed && (
                                            <div className="score"><i className="fas fa-play-circle"></i> Has played</div>
                                        )
                                    }
                                </div>
                                <div className="btns">
                                    {
                                        roomData.canControl && (<div className="btn btn-sm" onClick={() => playSong(song.songId)}><i className="fas fa-play"></i> Play</div>)
                                    }
                                    <div className="btn btn-sm" onClick={() => openRating(song, true)}><i className="fas fa-star"></i> Rate ({getMyRating(song)})</div>
                                    {
                                        (song.userId === roomData.userId || roomData.isAdmin)
                                        && (<div className="btn btn-sm red" onClick={() => removeSong(song.songId)}><i className="far fa-trash-alt"></i> Remove</div>)
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        chart.length === 0 && (
                            <div className="empty-chart">
                                <i className="fas fa-folder-open"></i>
                                <div>It's empty here, please add some songs</div>
                            </div>
                        )
                    }
                </div>
            </div>

            {
                modalAddSong && (
                    <Modal close={() => setModalAddSong(false)}>
                        <Input title={"Youtube Url"}
                               onChange={(value) => {setInputAddSong(value)}}
                               defaultValue={""}
                               placeholder={"example: https://www.youtube.com/watch?v=5wb5HWVh6Fs"}/>
                        <div className="btn" onClick={sendAddModal}><i className="fas fa-plus-square"></i> Add song</div>
                    </Modal>
                )
            }

            {
                modalRate && (
                    <Modal close={() => setModalRate(false)}>
                        <h1>{rating.song.songName}</h1>
                        <div className="points">
                            <div className="point" onClick={() => sendRating(-2)}>
                                <img src="/emojis/face_vomiting.gif" alt="-2"/>
                                <div className="modify-score">-2 points</div>
                                {rating.showConts && <div className="count">{rating.song.ratings.filter(s => s.rating === -2).length}x</div>}

                            </div>
                            <div className="point" onClick={() => sendRating(-1)}>
                                <img src="/emojis/crying_face.gif" alt="-1"/>
                                <div className="modify-score">-1 points</div>
                                {rating.showConts &&<div className="count">{rating.song.ratings.filter(s => s.rating === -1).length}x</div>}
                            </div>
                            <div className="point" onClick={() => sendRating(0)}>
                                <img src="/emojis/face_with_rolling_eyes.gif" alt="0"/>
                                <div className="modify-score">0 points</div>
                                {rating.showConts &&<div className="count">{rating.song.ratings.filter(s => s.rating === 0).length}x</div>}
                            </div>
                            <div className="point" onClick={() => sendRating(1)}>
                                <img src="/emojis/hushed_face.gif" alt="+1"/>
                                <div className="modify-score">+1 point</div>
                                {rating.showConts &&<div className="count">{rating.song.ratings.filter(s => s.rating === 1).length}x</div>}
                            </div>
                            <div className="point" onClick={() => sendRating(2)}>
                                <img src="/emojis/smiling_face_with_hearts.gif" alt="+2"/>
                                <div className="modify-score">+2 points</div>
                                {rating.showConts &&<div className="count">{rating.song.ratings.filter(s => s.rating === 2).length}x</div>}
                            </div>
                        </div>
                    </Modal>
                )
            }
            {
                shareModal && (
                    <Modal close={() => {setShareModal(false)}}>
                        <h1>How to invite more people to this room?</h1>
                        <p className={"text-center"}>Just copy this link and share it!</p>
                        <div onClick={() => copyText()} >
                            <Input title={"link (click to copy)"} id={"copy-text"} onChange={() => {}} defaultValue={"https://video-chart.puhony.eu/connect/"+props.roomId} disabled={false}/>
                        </div>


                        <div className="btn" onClick={() => {setShareModal(false)}}>Close this message</div>
                    </Modal>
                )
            }
        </div>
    );
};

export default Room;
