import React, {useState} from 'react';
import '../scss/style.scss';
import Input from "../components/Input";
import Divider from "../components/Divider";
import Toast from "../utils/Toast";
import AxiosInstance from "../utils/AxiosInstance";
import Footer from "../components/footer";
import {Link} from "react-router-dom";
import Checkbox from "../components/Checkbox";
import { useHistory } from "react-router"

const CreateRoom = () : JSX.Element => {
    const [values, setValues] = useState({roomName: "", musicControl: false, musicAdd: false});
    const [errors, setErrors] = useState({roomName: "", musicControl: "", musicAdd: ""});
    const history = useHistory();

    const createRoom = () => {
        let errors = {roomName: "", musicControl: "", musicAdd: ""};
        let error = false;

        if(values.roomName.length < 3) {errors.roomName = "Room name must have at least 3 characters!"; error = true;}

        setErrors(errors);

        if(!error) {
            AxiosInstance.post("/room/create", {
                musicControl: values.musicControl ? "admin" : "all",
                musicAdd: values.musicAdd ? "admin" : "all",
                roomName: values.roomName
            })
                .then((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Room created!'
                    });
                    history.push("/connect/"+response.data.roomId);
                })
                .catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Room was not created!'
                    });
                });
        }
    };

    const set = (i, value) => {
        let v = {...values};
        v[i] = value;
        setValues(v);
    }

    return (
        <div className="com-welcome">
            <div className="welcome">
                <div className="left">
                    <h1>Team Video Chart</h1>

                    <Input title={"Room name"} placeholder={"Insert room name"} errorMessage={errors.roomName} onChange={(value) => {set("roomName", value)}}/>

                    <Checkbox title={"Video control admin only"} errorMessage={errors.musicControl} onChange={(value) => {set("musicControl", value)}}/>

                    <Checkbox title={"Video managment admin only"} errorMessage={errors.musicAdd} onChange={(value) => {set("musicAdd", value)}}/>

                    <div className="btn w-100 green" onClick={createRoom}><i className="far fa-plus-square"></i> Create new room</div>

                    <Divider>OR</Divider>

                    <Link to="/">
                        <div className="btn w-100"><i className="fas fa-plug"></i> Connect</div>
                    </Link>
                </div>
                <div className="right" style={{backgroundImage: "url('/img/bg-1.jpg')"}}>

                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CreateRoom;
