import React from "react";

type Input = {
    title: string
    name?: string
    checked?: boolean
    errorMessage?: string
    onChange: Function
}

const Checkbox = (props : Input) : JSX.Element => {
    const setValue = (value) => {
        props.onChange(value);
    }

    return (
        <label className="form-label checkbox-form">
            <input type="checkbox" name={props.name} className="checkbox-input" checked={props.checked} onChange={(e) => setValue(e.target.checked)}/>
            <div className="checkbox"></div> <div className="label-title checkbox-title">{props.title}</div>

            <div className="input-error">{props.errorMessage} </div>
        </label>
    );
};

export default Checkbox;