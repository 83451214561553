import React from 'react';

type Input = {
    title: string
    name?: string
    id?: string
    defaultValue?: string | number
    placeholder?: string
    errorMessage?: string
    onChange: Function
    disabled?: boolean
}

const Input = (props : Input) : JSX.Element => {
    const setValue = (value) => {
        props.onChange(value);
    }

    return (
        <label className="form-label">
            <div className="label-title">{props.title}</div>
            <input type="text"
                   id={props.id}
                   name={props.name}
                   placeholder={props.placeholder}
                   className="inputs"
                   defaultValue={props.defaultValue}
                   disabled={props.disabled}
                   onChange={(e) => setValue(e.target.value)}/>
            <div className="input-error">{props.errorMessage} </div>
        </label>
    );
};

export default Input;