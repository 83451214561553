import React from 'react';

const Modal = (props) => {
    const handleCloseBtn = () => {
        props.close();
    }

    return (
        <div className="com-modal">
            <div className="modal">
                <div className="close" onClick={handleCloseBtn}><i className="fas fa-times"></i></div>
                {props.children}
            </div>
        </div>
    );

};

export default Modal;