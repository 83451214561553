import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useParams,
} from "react-router-dom";

import IndexPage from "./pages/IndexPage";
import CreateRoom from "./pages/createRoom";
import Room from "./pages/Room";

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/create-room">
                    <CreateRoom/>
                </Route>
                <Route path="/room/:roomId">
                    <RoomPage/>
                </Route>
                <Route path="/room">
                    <Room roomId={""}/>
                </Route>
                <Route path="/connect/:roomId">
                    <ConnectToRoom/>
                </Route>
                <Route path="/connect">
                    <IndexPage roomID={""}/>
                </Route>
                <Route path="/">
                    <Redirect to="/connect"/>
                </Route>
            </Switch>
        </Router>
    )
};

function ConnectToRoom() {
    let { roomId } = useParams();

    return (
        <IndexPage roomID={roomId}/>
    );
}

function RoomPage() {
    let { roomId } = useParams();

    return (
        <Room roomId={roomId}/>
    );
}



export default App;