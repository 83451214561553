import React from "react";
import Plyr from "plyr";
import { create as tsCreate } from "timesync/dist/timesync.js"
import { Config } from "../config";

class Player2 extends React.Component<any, any> {
    private player: any;
    private offset: number;
    private ts: any;
    private int: NodeJS.Timeout;

    constructor(props) {
        super(props);

        this.ts = tsCreate({
            server: Config.HTTP_API_URI + '/timesync'
        });

        const setOffset = (offset) => {
            this.offset = offset
        }

        this.ts.on('change', setOffset.bind(this));

        this.state = {
            buffering: false,
            serverTime: 0,
            timeDiff: 0
        }
    }

    componentDidMount() {
        this.player = new Plyr(document.getElementById('player-frame'), {
            controls: ["fullscreen", "volume", "mute", "current-time", 'captions', "settings", "pip"],
            settings: ['quality', 'captions'],
            clickToPlay: false,
            keyboard: {focused: false, global: false},
            youtube: {rel: 0},
            quality: {default: 480, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240]}
        });

        this.int = setInterval(this.interval.bind(this), 200)
    }

    componentWillUnmount() {
        clearInterval(this.int)
    }

    interval() {
        const st = this.getVideoServerTime()
        this.setState({serverTime: Math.round(st * 1000) / 1000})

        if(this.props.data.state === "playing" && st >= 0 && !this.player.playing) {
            this.setState({buffering: false})
            this.player.play()
            this.player.muted = false
        }

        if(this.props.data.state === "playing" && st >= 0 && this.player.playing && (this.player.duration - 2) > st) {
            const diff = st - this.player.currentTime

            this.setState({diff: Math.round(diff * 1000) / 1000})

            if(diff <= -5 || diff >= 5) {
                this.player.currentTime = st
                this.player.play()
            } else if(diff <= -0.2 || diff >= 0.2) {
                this.player.speed = diff > 0 ? 1.25 : 0.75
            } else {
                this.player.speed = 1
            }
        }

        if(this.props.data.state === "paused") {
            this.player.pause()
        }

        if((this.player.duration - 2) < st) {
            this.player.pause()
        }
    }

    initVideo() {
        this.setState({buffering: true})

        this.player.source = {
            type: "video",
            sources: [
                {
                    src: 'https://www.youtube.com/watch?v=' + this.props.data?.song?.youtubeId ?? "XEjLoHdbVeE",
                    provider: 'youtube',
                }
            ]
        }

        this.player.on('ready', event => {
            const instance = event.detail.plyr;
            instance.pause()
            instance.muted = true
        });
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if(prevProps.data?.song?.youtubeId !== this.props.data?.song?.youtubeId) {
            this.initVideo()
        }
    }

    handlePlay() {
        this.props.client.send(JSON.stringify({
            type: "song-change",
            newSongId: this.props.data.song.songId,
            state: "playing",
            time: this.player.currentTime
        }));
    };

    handlePause() {
        this.props.client.send(JSON.stringify({
            type: "song-change",
            newSongId: this.props.data.song.songId,
            state: "paused",
            time: this.getVideoServerTime()
        }));
    };

    getVideoServerTime() {
        const now = this.ts ? new Date(this.ts.now()) : new Date()
        const since = this.props.data.since ? new Date(this.props.data.since * 1000) : now

        let time = (now.getTime() - since.getTime()) / 1000 + this.props.data.time

        if(this.player.duration < time) {
            time = this.player.duration
        }

        return time
    }

    restart() {
        this.player.source = {
            type: "video",
            sources: [
                {
                    src: 'https://www.youtube.com/watch?v=' + "XEjLoHdbVeE",
                    provider: 'youtube',
                }
            ]
        }

        this.initVideo()
    }

    render() {
        return (
            <>
                {
                    !this.props.data.song && (
                        <>
                            <div className="player-empty">
                                <div>
                                    <h1>Welcome! let's the party begin!</h1>
                                    <p>Add song and play!</p>
                                </div>
                            </div>
                        </>
                    )
                }

                <div className="player" style={{display: this.props.data?.song?.youtubeId ? "block" : "none"}}>
                    <div className={"full"}>
                        <div id="player-frame" data-plyr-provider="youtube"
                             data-plyr-embed-id={this.props.data?.song?.youtubeId ?? " "}/>
                    </div>

                    <div className="buffer-screen" style={{display: this.state.buffering ? "flex" : "none"}}>
                        <div>
                            <i className="fas fa-sync"></i>
                            <h2>Video is Buffering, please wait</h2>
                        </div>
                    </div>
                </div>

                {
                    this.props.data.song && (
                        <>
                            <h2 className={"song-name"}>{this.props.data.song.songName}</h2>
                            <p className={"song-name-subline"}>Added by {this.props.data.song.userName}</p>
                            {
                                this.props.roomData.canControl
                                && <>
                                    {
                                        this.props.data.state === "paused" ? (
                                            <div className="btn" onClick={this.handlePlay.bind(this)}><i
                                                className="fas fa-play"></i> Play</div>
                                        ) : (
                                            <div className="btn" onClick={this.handlePause.bind(this)}><i
                                                className="fas fa-pause"></i> Pause</div>
                                        )
                                    }
                                </>
                            }
                            <div className="btn" onClick={() => this.props.openRating(this.props.data.song, false)}><i
                                className="fas fa-star"></i> Rate
                            </div>
                            <div className="btn" onClick={this.restart.bind(this)}><i
                                className="fas fa-tools"></i> Video is unavailable (fix)
                            </div>
                        </>
                    )
                }

                <p className={'sync-time'}>
                    Sync time from server: {Math.floor(this.offset)} ms,
                    video server time {this.state.serverTime}s,
                    time diff: {this.state.diff}s</p>
            </>
        )
    }
}

export default Player2
