import React, {useEffect, useState} from "react";
import AxiosInstance from "../utils/AxiosInstance";

const Footer = () => {
  const [onlineInfoPlayers, setOnlineInfoPlayers] = useState(0)
    const [onlineInfoRooms, setOnlineInfoRooms] = useState(0)

    const updateStat = () => {
        AxiosInstance.get('/stats')
            .then((response) => {
                setOnlineInfoPlayers(response.data.activeUsers)
                //setOnlineInfoRooms(response.data.activeRooms)
            })
            .catch(() => {})
    }

  useEffect(() => {
      const interval = setInterval(() => {
          updateStat()
      }, 5000)

      return () => {
        clearInterval(interval)
      }
  }, [])

  return (
      <div className="text-right container-900"> {onlineInfoPlayers > 0 && <>online: {onlineInfoPlayers} users | </> }Created by Matěj and Vítek 2020</div>
  );
};

export default Footer;
