import React from "react";

type Divider = {
    children: string
};

const Divider = (props) => {
    return (
        <div className="divider">
            <div className="line"></div>
            <div className="text">{props.children}</div>
            <div className="line"></div>
        </div>
    );
};

export default Divider;